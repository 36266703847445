import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { NoticeDeployment } from '@types';
import { buildUrl, isValidDeployment } from '@utils';
import { useFetchAllPaginatedResults } from './useFetchAllPaginatedResults.hook';

/**
 * Fetch notice deployments
 * */
export const useNoticeDeployments = (options?: UseQueryOptions<NoticeDeployment[]>) => {
  const { organizationId } = useActiveOrganization();
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();

  const fetchDeployments = async (): Promise<NoticeDeployment[]> => {
    const params = {
      $select: [
        'id',
        'platform',
        'targets',
        'notice_id',
        'deployed_at',
        'deployed_by',
        'deployed_by_name',
        'deployed_metadata',
      ],
      organization_id: organizationId,
      $with: ['notice_deployment'],
    };
    const url = buildUrl(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs`, params);
    const deployments = await fetchAllPaginatedResults<NoticeDeployment>(url);

    return deployments.filter(isValidDeployment);
  };

  return useQuery<NoticeDeployment[], AxiosError>(
    QUERY_KEYS.DEPLOYMENTS(organizationId),
    fetchDeployments,
    options,
  );
};
